<template>
    <AntiCheatingPolicy :show="showAntiCheat" :toggleModal="toggleAntiCheat" />

    <div class="cheating-container">
        <div class="w-full flex justify-between p-3 bg-[#fff] border-b items-center rounded mb-5 shadow-card">
            <button class="w-[60px] h-[50px] rounded-[10px] bg-black/5 text-gray-700 hover:bg-black/10 block" @click="goBackFunction()">
                <font-awesome-icon :icon="['fas', 'angle-left']" />
            </button>
            <!-- <div class="flex items-center justify-end gap-5">
                <p>Based on the analyse you did below, do you consider this candidate a cheater ?</p>
                <div class="flex items-center justify-center gap-2">
                    <button @click="deleteCheater()" class="w-[80px] h-[50px] rounded-[10px] bg-black/5 text-gray-700 hover:bg-black/10 menuBtn block">No</button>
                    <button @click="validateCheater()" class="nextStep shadow ml-auto">Yes</button>
                </div>
            </div> -->
        </div>
        <div v-if="isLoading" class="loading">
            <LoaderComponentBlue />
        </div>
        <div v-else class="flex flex-col w-full gap-5">
            <div class="flex lg:flex-row gap-5 w-[100%]">
                <div class="w-full lg:w-[50%] p-2 rounded-md shadow-card bg-[#fff]">
                    <span class="recentapp flex items-center justify-between w-full">
                        {{ $t("Candidate Card") }}
                        <font-awesome-icon @click="showAntiCheat = true" class="font-light text-xs w-3 h-3 p-1 ml-3 rounded-full border border-slate-700" :icon="['fas', 'question']"
                    /></span>
                    <div class="flex flex-col justify-start items-start gap-3 py-6 px-1">
                        <div class="w-full flex flex-row justify-between items-center">
                            <h2 class="text-sm font-light">Device used</h2>
                            <span class="font-bold text-sm">Desktop</span>
                        </div>
                        <div class="w-full flex flex-row justify-between items-center">
                            <h2 class="text-sm font-light">Location</h2>
                            <span class="font-bold text-sm">{{ cheater?.Location }}</span>
                        </div>
                        <div class="w-full flex flex-row justify-between items-center">
                            <h2 class="text-sm font-thin">Mouse always in assessment window?</h2>
                            <span
                                class="font-normal text-sm rounded-sm w-[15%] px-1 text-center mr-1 text-white"
                                :class="{ 'bg-red-800': cheater?.mouseExited, 'bg-green-800': !cheater?.mouseExited }"
                            >
                                {{ !cheater?.mouseExited ? "Yes" : "No" }}</span
                            >
                        </div>
                        <div class="w-full flex flex-row justify-between items-center">
                            <h2 class="text-sm font-thin">Full-screen mode always active?</h2>
                            <span
                                class="font-normal text-sm rounded-sm w-[15%] px-1 text-center mr-1 text-white"
                                :class="{ 'bg-red-800': cheater?.fullScreenExited, 'bg-green-800': !cheater?.fullScreenExited }"
                            >
                                {{ !cheater?.fullScreenExited ? "Yes" : "No" }}</span
                            >
                        </div>
                        <div class="w-full flex flex-row justify-between items-center">
                            <h2 class="text-sm font-thin">Stayed within assessment window?</h2>
                            <span class="font-normal text-sm rounded-sm w-[15%] px-1 text-center mr-1 text-white" :class="{ 'bg-red-800': cheater?.exitedTab, 'bg-green-800': !cheater?.exitedTab }">
                                {{ !cheater?.exitedTab ? "Yes" : "No" }}</span
                            >
                        </div>
                        <div v-if="cheater?.exitedTab" class="w-full flex flex-row justify-between items-center">
                            <h2 class="text-sm font-thin">Number of times window has changed :</h2>
                            <span
                                class="font-normal text-sm rounded-sm w-[15%] px-1 text-center mr-1 text-white"
                                :class="{ 'bg-red-800': cheater?.exitCount > 0, 'bg-green-800': cheater?.exitCount === 0 }"
                            >
                                {{ cheater?.exitCount }}</span
                            >
                        </div>
                    </div>
                </div>
                <div class="w-full lg:w-[50%] p-2 rounded-md shadow-card bg-[#fff]">
                    <span class="recentapp flex items-center justify-between w-full"> {{ $t("Personal Informations") }}</span>
                    <div class="flex items-start gap-5 py-[2rem] px-1">
                        <img
                            loading="lazy"
                            class="w-[30%] h-[12rem] rounded border-[1px] border-[#D9D9D9] border-[#ccc]"
                            decoding="async"
                            :src="'data:image/png;base64,' + this.cheater.avatar"
                            alt=""
                        />

                        <div class="flex w-[70%] flex-col gap-5 items-start">
                            <div class="w-full flex flex-row justify-between items-center">
                                <h2 class="text-sm font-light">First Name</h2>
                                <span class="font-bold text-sm">{{ cheater?.First_name }}</span>
                            </div>
                            <div class="w-full flex flex-row justify-between items-center">
                                <h2 class="text-sm font-light">Last Name</h2>
                                <span class="font-bold text-sm">{{ cheater?.Last_name }}</span>
                            </div>
                            <div class="w-full flex flex-row justify-between items-center">
                                <h2 class="text-sm font-thin">Email</h2>
                                <span class="font-bold text-sm">{{ cheater?.email }}</span>
                            </div>
                            <div class="w-full flex flex-row justify-between items-center">
                                <h2 class="text-sm font-thin">Phone Number</h2>
                                <span class="font-bold text-sm">{{ cheater?.phone }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full p-2 rounded-md shadow-card bg-[#fff]">
                <span class="recentapp"> {{ $t("Captured Photos") }}</span>
                <div v-if="captured_photos" class="captured-photos">
                    <div v-for="(photo, index) in cheater.images" :key="index">
                        <img loading="lazy" decoding="async" :src="photo.image" alt="" />
                        <span class="date">{{ photo.Date }}</span>
                    </div>
                </div>
                <div v-else class="w-full h-[250px] flex items-center justify-center">
                    <span class="text-[#bbbbbb] font-dm-sans text-2xl">No Photos have been captured</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoaderComponentBlue from "@/components/LoaderComponentBlue";
import AntiCheatingPolicy from "@/components/AntiCheatingPolicy.vue";
import { useStore } from "@/store/index";
import dummyData from "../../dummyData/dummyData.js";
import dummyCheaters from "../../dummyData/cheaters.js";

export default {
    name: "DummyCheatingCard",
    components: {
        LoaderComponentBlue,
        AntiCheatingPolicy,
        //   ButtonComponent
    },
    data() {
        return {
            currentIndex: 1,
            cheater: {},
            candidate: {},
            captured_photos: [],
            candidateEmail: "",
            project_id: "",
            isLoading: false,
            showAntiCheat: false,
            candidateInfo: this.Store.candidateInfoAct,
        };
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    methods: {
        goBackFunction() {
            this.$router.go(-1);
        },
    },
    mounted() {
        // this.getCheaterId();
        // this.getCapturedPhotos();
        let dummy = dummyData.data;
        this.candidate = dummy.candidates.find((c) => c._id == this.$route.params.id);
        this.cheater = dummyCheaters.find((c) => c._id == this.$route.params.id);
        console.log({ DUMMYCHEATTER: this.cheater });
    },
};
</script>
<style scoped>
.nextStep {
    width: 80px;
    height: 50px;
    color: white;
    font-weight: 500;
    background: #2196f3;
    border-radius: 10px;

    &:hover {
        opacity: 0.85;
    }
}

.cheating-container {
    height: 100%;
    width: 100%;
    display: flex;
    padding-top: 40px;
    margin: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.left-side img {
    width: 200px;
    height: 250px;
}

.separator {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 90%;
    margin-top: 4rem;
}

h3 {
    color: #646464;
}

.line {
    width: 40%;
    height: 2px;
    background: #d9d9d9;
}

.cheating-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 800px;
    padding: 2rem 1rem;
    width: 100%;
}

.identity-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    background: #fff;
    border-radius: 20px;
    padding: 1rem;

    box-shadow:
        20px 20px 60px #bebebe,
        -20px -20px 60px #ffffff;
}

.identity-container {
    display: flex;
    align-items: center;
    gap: 2rem;

    width: 100%;
}

h1 {
    margin-bottom: 1rem;
    font-size: 18px;
    font-weight: 600;
}

.right-side {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    justify-content: space-evenly;
}

.captured-photos {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 1rem;
}

.captured-photos > div {
    width: 25%;
    /* Four columns, each taking 25% of the width */
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.captured-photos img {
    width: 100%;
    height: 170px;
}

.back-container {
    width: 100%;
    display: flex;
    color: #2196f3;
    margin-top: 2rem;
}

.confirm-btn {
    background: #53b9ab !important;
}

.cancel-btn {
    background-color: red;
}

.recentapp {
    color: #1b2559;
    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
    letter-spacing: -0.4px;
}

.buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 90%;
    padding-bottom: 2rem;
}

.back-container span:hover {
    text-decoration: underline;
}

.loading {
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
